@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400&display=swap");

:root body {
  font-family: "Kanit", sans-serif;
  line-height: 1.5;
  font-display: swap;
}

.font-sarabun {
  font-family: 'Sarabun', sans-serif;
  line-height: 1.5;
  font-display: swap;
}
